import React ,{useState} from 'react';
import Layout from "../components/layout";
import styled from "styled-components";
import colors from '../components/theme'
import { graphql, useStaticQuery,Link,navigate } from 'gatsby';
import { Helmet } from "react-helmet";

const ThankYouDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-size: 35px;
font-family: rubik;
flex-direction:column;
height: 85vh;
width: 100vw;
background-color: #FFFFFF;
color: #FFFFFF
`
const ThankYouTextDiv = styled.div`
width: 50%;
text-align: center
`

const ThankYouTextHeading = styled.h2`
color: ${colors.black};
font-size: 1em;
margin:0
`

const ThankYouTextSubHeading = styled.h3`
color: ${colors.black};
font-size: 0.75em;
@media(max-width: 425px){
    width: 80%;
    text-align: center;
}
`

const ThankyouButton = styled.button`
border-radius: 5px;
    border: none;
    box-shadow: lightgrey 1px 2px 3px;
    font-family: rubik;
    text-transform: uppercase;
    font-weight: 200;
    color: black;
    background-color: rgb(212, 244, 239);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
height: 50px;

// color:black !important;
// width: 30%;
// margin: 1em;
// text-transform: uppercase;
// font-weight: 200;
// background-color: ${colors.blue};
// color: #fff;
// cursor: pointer;
// border-radius: 30px;
// z-index: 4;
// font-size: 0.5em;
@media(max-width: 425px){
    width: 80%;
}
`

const ThankYouLander = () => {

    const [contactModalDropDownOpen, setcontactModalDropDownOpen] = useState(false);

    const onTourClickHandler = () => {
        setcontactModalDropDownOpen(true)
        navigate('/')
      } 

    return (
        <>
        <Helmet>
        <title>Square One - 27th &amp; Northgate - Thank You</title>
          <meta name="title" content="Square One - 27th &amp; Northgate - Thank You" />
        
        </Helmet>
        <Layout>
            <ThankYouDiv>
                <ThankYouTextHeading>
                Thank You!  
                </ThankYouTextHeading>
                <ThankYouTextSubHeading>
                Our community manager will reach out to you shortly.
                </ThankYouTextSubHeading>
                <ThankyouButton onClick={()=> onTourClickHandler()}>Go Back To Home Page</ThankyouButton>
            </ThankYouDiv>
            
            
        </Layout>
        </>
    )
}

export default ThankYouLander; 